import { Spinner, Text, VStack } from '@chakra-ui/react'
import { LocaleText } from '../context/language'

export function LoadingState({ ...props }) {
  return (
    <VStack textAlign="center" {...props}>
      <Text fontFamily="bold">
        <LocaleText id="Text.Loading" />
      </Text>
      <Spinner size="lg" color="blue.900" />
    </VStack>
  )
}
