import {
  VStack,
  Container,
  Box,
  Button,
  Flex,
  Center,
  Heading,
  Text,
  useDisclosure,
  Fade,
  chakra,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as Drawing1 } from '../assets/img/drawing-1.svg'
import { ReactComponent as QuestionIcon } from '../assets/img/question.svg'
import { ReactComponent as TimerIcon } from '../assets/img/timer.svg'
import { useLanguage } from '../context/language'
import { useLibrary } from '../context/library'
import { useQuestionnaire } from '../context/questionnaire'
import { useReset } from '../hooks/useReset'
function HomeScreen() {
  const reset = useReset()
  const navigate = useNavigate()

  const { language, t } = useLanguage()
  const { fetchQuestionnaire } = useQuestionnaire()

  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })

  // This is used to fade out screen content before navigating to question page
  const [hasStarted, setHasStarted] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const { library, user } = useLibrary()

  const loginState = user ? (
    <VStack>
      <Text textAlign="center">
        {t('Home.LoggedAs')}{' '}
        <chakra.span fontFamily="medium">
          {user.firstName} {user.lastName}
        </chakra.span>
      </Text>
      <Button variant="solid" onClick={() => navigate('/profile')}>
        {t('Button.MyProfile')}
      </Button>
      {user.isAdmin && (
        <Button variant="solid" colorScheme="red" onClick={() => navigate('/admin/dashboard/overview')}>
          {t('Button.AdminDashboard')}
        </Button>
      )}
      <Button variant="ghost" onClick={() => library.signOut()} colorScheme="red">
        {t('Button.Disconnect')}
      </Button>
    </VStack>
  ) : (
    <Button
      size="md"
      height="40px"
      p={5}
      color="blue"
      onClick={() => {
        navigate('/signin')
      }}
    >
      {t('Button.Login')}
    </Button>
  )

  // Resets the state of the questionnaire responses when going back to Home
  useEffect(() => {
    reset()
  }, [reset])

  // Pre-fetches the questionnaire to avoid having a loading state on start
  useEffect(() => {
    setLoading(true)
    fetchQuestionnaire(language).then(() => setLoading(false))
  }, [fetchQuestionnaire, language])

  return (
    <Container mt={16} maxW="3xl">
      <Fade in={isOpen} onAnimationComplete={() => (hasStarted ? navigate('/questions') : null)}>
        <Flex direction={['column', 'column', 'row', 'row']}>
          <Flex order={[2, 2, 1, 1]} flex="2" direction="column" align="center" justify="space-around">
            <Heading my={8} textAlign="center">
              {t('Home.Heading')}
            </Heading>
            <Button
              size="lg"
              width="80%"
              height="60px"
              fontSize="22px"
              borderRadius="10px"
              colorScheme="yellow"
              fontWeight="bold"
              color="blue"
              onClick={() => {
                // Prevent changing page if questionnaire was not totally fetched
                if (!isLoading) {
                  setHasStarted(true)
                  onClose()
                }
              }}
            >
              {t('Home.Button.Start')}
            </Button>
            <Center my={5}>{loginState}</Center>
          </Flex>
          <Center order={[1, 1, 2, 2]} flex="1">
            <Drawing1 />
          </Center>
        </Flex>
        <Flex alignItems="center" flex="1" my={8} direction={['column', 'column', 'row', 'row']}>
          <Box flex="1" mb={10}>
            <Center>
              <QuestionIcon />
            </Center>
            <Heading my={3} size="md" textAlign="center">
              {t('Home.WhatDoIGet')}
            </Heading>
            <Text width="sm" textAlign="center">
              {t('Home.Description')}
            </Text>
          </Box>
          <Box flex="1" pb={10}>
            <Center>
              <TimerIcon />
            </Center>
            <Heading mt="20px" size="md" textAlign="center">
              {t('Home.TimeTaken')}
            </Heading>
          </Box>
        </Flex>
      </Fade>
    </Container>
  )
}

export { HomeScreen }
