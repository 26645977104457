import { useMemo } from 'react'
import { useLibrary } from '../context/library'

export const useStats = () => {
  const { library } = useLibrary()

  const stats = useMemo(
    () => ({
      getNumberOfResultsInPeriod: async (startDate, endDate) => {
        const result = await library.QuestionnaireResult.count({
          end_time: {
            $gte: startDate,
            $lte: endDate,
          },
        })

        return result
      },
      getTotalNumberOfResults: async () => {
        const result = await library.QuestionnaireResult.count({})

        return result
      },
    }),
    [library],
  )

  return stats
}
