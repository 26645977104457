import { Routes, Route, Navigate } from 'react-router-dom'
import { HomeScreen } from './screens/HomeScreen'
import { QuestionScreen } from './screens/QuestionScreen'
import { NotFoundScreen } from './screens/NotFoundScreen'
import { ResultScreen } from './screens/ResultScreen'
import { Center, Flex } from '@chakra-ui/react'
import { Header } from './components/Header'
import { AuthenticationScreen } from './screens/AuthenticationScreen'
import { SignupScreen } from './screens/SignupScreen'
import { PDFViewer } from '@react-pdf/renderer'
import { ResultsPDF } from './components/pdf/ResultsPDF'
import dummyResults from './components/pdf/dummy.json'
import { UserProfileScreen } from './screens/UserProfileScreen'
import { AdminDashboardScreen } from './screens/AdminDashboardScreen'
import { SingleUserScreen } from './screens/SingleUserScreen'

function App() {
  return (
    <Flex height="100vh" direction="column" color="blue.900">
      <Header />
      <AppRoutes />
    </Flex>
  )
}

function Debug() {
  return (
    <Center width="100%" height="100%">
      <PDFViewer width="100%" height="100%">
        <ResultsPDF results={dummyResults} />
      </PDFViewer>
    </Center>
  )
}

function AppRoutes() {
  return (
    <Routes>
      <Route path="/questions">
        <Navigate to="/questions/0" />
      </Route>
      <Route path="/questions/:questionIndex" element={<QuestionScreen />} />
      <Route path="/signin" element={<AuthenticationScreen />} />
      <Route path="/signup" element={<SignupScreen />} />
      <Route path="/profile" element={<UserProfileScreen />} />
      <Route path="/admin/dashboard/*" element={<AdminDashboardScreen />} />
      <Route path="/results/:id" element={<ResultScreen />} />
      <Route path="/user/:id" element={<SingleUserScreen />} />
      {process.env.NODE_ENV === 'development' && <Route path="/debug" element={<Debug />} />}
      <Route path="/" element={<HomeScreen />} />
      <Route path="*" element={<NotFoundScreen />} />
    </Routes>
  )
}

export { App }
