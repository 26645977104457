import { Box, Flex } from '@chakra-ui/react'

export function ScoreBadge({ score, ...props }) {
  return (
    <Flex
      w="180px"
      p={1}
      bgColor="blue.900"
      color="white"
      align="center"
      justify="center"
      borderRadius={5}
      boxShadow="box"
      {...props}
    >
      <Box fontFamily="bold" fontSize="2.8em">
        {Math.ceil(score)} %
      </Box>
    </Flex>
  )
}
