import { Box, chakra, Collapse, Flex, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { Badge } from '../Badge'
import { Badge as ChakraBadge } from '@chakra-ui/react'
import { ProgressBar } from '../ProgressBar'
import { LocaleText, useLanguage } from '../../context/language'

const QuestionDetails = ({ result, ...props }) => {
  const { question, answers } = result

  let answersDisplay

  if (result.kind === 'link') {
    answersDisplay = 'todo: link'
  } else if (result.kind === 'single' || result.kind === 'multiple') {
    answersDisplay = answers.map((ans) => (
      <Text key={ans} mb={1}>
        {question.form.choices[ans].title}
      </Text>
    ))
  }

  return (
    <Box py={3}>
      <Text fontFamily="medium">{question.question}</Text>
      <Text fontStyle="italic" fontSize="0.9rem" fontFamily="light">
        <LocaleText id="Results.YourAnswers" />
      </Text>
      <ChakraBadge fontSize="0.7em" colorScheme={result.isCorrect ? 'green' : 'red'}>
        {result.isCorrect ? 'Correct' : 'Wrong'}
      </ChakraBadge>
      <Box pl={5}>{answersDisplay}</Box>
    </Box>
  )
}

export function CategoryScore({
  titleFontFamily,
  categoryNumber,
  isOpen,
  results,
  title,
  score,
  scoreMax,
  color: colorProp,
  ...props
}) {
  const [color, setColor] = useState('blue.500')
  const [scoreReview, setScoreReview] = useState(null)

  const { t } = useLanguage()

  const scorePercentage = scoreMax > 0 ? Math.floor((score / scoreMax) * 100) : 0

  useEffect(() => {
    if (!colorProp) {
      if (scorePercentage < 25) {
        setColor('red.600')
        setScoreReview('bad')
        return
      } else if (scorePercentage < 75) {
        setColor('yellow.500')
        setScoreReview('medium')
      } else {
        setColor('blue.500')
        setScoreReview('good')
        return
      }
    }
  }, [colorProp, scorePercentage])

  let badge = null

  if (scoreReview === 'bad' || scoreReview === 'medium') {
    badge = (
      <ChakraBadge p={1} fontSize="0.6em" ml={3} colorScheme="red">
        {t('Results.Score.ToReview')}
      </ChakraBadge>
    )
  }
  if (scoreReview === 'medium') {
    badge = (
      <ChakraBadge p={1} fontSize="0.6em" ml={3} colorScheme="yellow">
        {t('Results.Score.ToReview')}
      </ChakraBadge>
    )
  }

  const categoryScore = (
    <>
      <chakra.span fontFamily="bold">{scorePercentage}</chakra.span>
      <chakra.span fontFamily="normal">%</chakra.span>
    </>
  )

  if (scoreMax === 0) {
    // Case where no questions of a category has been answered (in development mode for example)
    return null
  }

  const style = {
    borderRadius: '10px',
    cursor: 'pointer',
  }

  return (
    <>
      <Flex pl={2} pr={2} direction="column" {...style} {...props}>
        <Flex mt={5} mb={1} wrap align="center">
          <Box mr={3}>
            <Badge value={categoryNumber} color={color} />
          </Box>
          <Text fontFamily={titleFontFamily ?? 'normal'}>{title}</Text>
          {badge}
        </Flex>
        <Flex wrap align="center">
          <ProgressBar color={color} width="1px" flexBasis="90%" value={scorePercentage} />
          <Text flexBasis="10%" pl={5}>
            {categoryScore}
          </Text>
        </Flex>
      </Flex>
      <Collapse in={isOpen}>
        {results.map((r) => (
          <QuestionDetails key={r.question.question} result={r} />
        ))}
      </Collapse>
    </>
  )
}

CategoryScore.defaultProps = {
  score: 0,
  scoreMax: 10,
}
