import { Box, Center, HStack, VStack } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useScore } from '../../context/score'

function ItemChoice({ text, isLeft, isRight, textLeft, textRight, isSelected, isLinked, disabled, ...props }) {
  let style = {}

  let defaultStyle = {
    bgColor: 'white',
  }

  let leftSideProps = {
    hidden: isLeft ? true : false,
    borderLeftRadius: '5px',
    bgColor: 'blue.900',
  }

  let rightSideProps = {
    hidden: isRight ? true : false,
    borderRightRadius: '5px',
    bgColor: 'blue.900',
  }

  const selectedStyle = {
    bgColor: 'yellow.500',
    color: 'blue.900',
    // borderColor: 'yellow.500',
    // borderWidth: '3px',
  }

  if (isSelected || isLinked) {
    style = {
      ...defaultStyle,
      ...selectedStyle,
    }
  }

  if (disabled) {
    const disabledStyle = {
      opacity: 0.6,
      cursor: 'not-allowed',
    }

    style = {
      ...style,
      ...disabledStyle,
    }

    leftSideProps = {
      ...leftSideProps,
      ...disabledStyle,
      color: 'green.500',
      fontFamily: 'bold',
    }

    rightSideProps = {
      ...rightSideProps,
      ...disabledStyle,
      color: 'green.500',
      fontFamily: 'bold',
    }
  }

  const hoverStyle = {
    bgColor: 'yellow.300',
    transition: 'background-color 0.1s ease-in',
  }

  return (
    <HStack
      disabled={disabled}
      fontFamily="medium"
      borderRadius="5px"
      as="button"
      boxShadow="box"
      spacing={0}
      align="stretch"
      {...props}
    >
      <Center color="white" flex="1" {...leftSideProps}>
        {textLeft}
      </Center>
      <Box
        textAlign="left"
        px={5}
        py={5}
        flexBasis="92%"
        minHeight="3rem"
        fontSize="0.9em"
        transition="background-color 0.1s ease-out"
        _hover={hoverStyle}
        {...style}
      >
        {text}
      </Box>
      <Center color="white" flex="1" {...rightSideProps}>
        {textRight}
      </Center>
    </HStack>
  )
}

export function LinkForm({ form, hasSubmitted, setHasSubmitted, setSubmitDisabled, question, handleChange, ...props }) {
  const [answers, setAnswers] = useState([])
  const [startSelect, setStartSelect] = useState(null)
  const [endSelect, setEndSelect] = useState(null)

  const { currentResult } = useScore()

  useEffect(() => {
    if (currentResult?.kind === 'link') {
      setAnswers(currentResult.answers)
      setHasSubmitted(currentResult.answers.length === form.start_choices.length)
    } else {
      setHasSubmitted(false)
    }
  }, [currentResult, setHasSubmitted, form])

  useEffect(() => {
    handleChange({ answers })
  }, [answers, handleChange])

  useEffect(() => {
    setSubmitDisabled(answers.length !== form.start_choices.length)
  }, [setSubmitDisabled, answers, form])

  const handleClick = (type, value) => {
    const toggleSelection = (prev) => (prev === value ? null : value)

    if (type === 'start') {
      // Handle click on a start button

      if (endSelect !== null) {
        // If end button was already selected, it creates a link and adds answer to state
        setAnswers((prev) => [...prev.filter((el) => el.start !== value), { start: value, end: endSelect }])

        setEndSelect(null)
        setStartSelect(null)
      } else {
        if (answers.every((el) => el.start !== value)) {
          setStartSelect(toggleSelection)
        }
        setAnswers((prev) => prev.filter((el) => el.start !== value))
      }
    }

    if (type === 'end') {
      if (startSelect !== null) {
        setAnswers((prev) => [...prev.filter((el) => el.end !== value), { start: startSelect, end: value }])

        setEndSelect(null)
        setStartSelect(null)
      } else {
        if (answers.every((el) => el.end !== value)) {
          setEndSelect(toggleSelection)
        }

        setAnswers((prev) => prev.filter((el) => el.end !== value))
      }
    }
  }

  return (
    <HStack justifyContent="space-between">
      <VStack width="45%" spacing={6}>
        {form.start_choices.map(({ title }, i) => {
          const answersDisplayed = hasSubmitted ? form.answers : answers
          const answer = answersDisplayed.find((el) => el.start === i)

          return (
            <ItemChoice
              isLeft
              isSelected={startSelect === i}
              isLinked={answers.some((ans) => ans.start === i)}
              key={title}
              text={title}
              onClick={(e) => {
                e.preventDefault()
                handleClick('start', i)
              }}
              textRight={answer ? answer.end + 1 : null}
              disabled={hasSubmitted}
            />
          )
        })}
      </VStack>
      <VStack width="45%" spacing={6}>
        {form.end_choices.map(({ title }, i) => {
          const answer = answers.find((el) => el.end === i)
          return (
            <ItemChoice
              isRight
              isSelected={endSelect === i}
              isLinked={answers.some((ans) => ans.end === i)}
              key={title}
              text={title}
              onClick={(e) => {
                e.preventDefault()
                handleClick('end', i)
              }}
              textLeft={answer ? String.fromCharCode(65 + answer.start) : null}
              disabled={hasSubmitted}
            />
          )
        })}
      </VStack>
    </HStack>
  )
}
