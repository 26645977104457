import { Box, chakra, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Tooltip } from '@chakra-ui/react'

const toPercentage = (val, max) => (val / max) * 100 + '%'

export function ProgressBar({ barHeight, thumbSize, color, marks = [], marksProps = [], ...props }) {
  const Marker = ({ text, position, size = 14, label, ...props }) => {
    return (
      <Tooltip fontFamily="bold" hasArrow placement="top" label={label}>
        <Box
          boxShadow="md"
          position="absolute"
          _hover={{
            bgColor: 'yellow.300',
          }}
          top={-size / 2 + 2 + 'px'}
          left={position}
          // This is to center both circles with different radius
          transform={`translateX(-7px)`}
          borderRadius="100%"
          width={size + 'px'}
          height={size + 'px'}
          bgColor="yellow.500"
          {...props}
        >
          <Box position="absolute" top={3} left="2px">
            <chakra.span fontFamily="black" fontSize="0.8rem" color={props.color}>
              {text}
            </chakra.span>
          </Box>
        </Box>
      </Tooltip>
    )
  }

  return (
    <Slider step={1} {...props}>
      <SliderTrack overflow="visible" height={barHeight}>
        <SliderFilledTrack bg={color} />
        {marks.map((mark, i) => (
          <Marker
            label={mark.title}
            text={i + 1}
            key={i}
            position={toPercentage(mark.value, props.max)}
            {...marksProps[i]}
          />
        ))}
      </SliderTrack>
      <SliderThumb bg={color} boxSize={thumbSize} />
    </Slider>
  )
}

ProgressBar.defaultProps = {
  barHeight: '3px',
  thumbSize: 2,
  color: 'blue.500',
}
