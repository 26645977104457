import { Box, Button, Flex, Text, VStack, useBreakpointValue, useDisclosure } from '@chakra-ui/react'
import { useQuestionnaire } from '../context/questionnaire'
import { RadarChart } from '../components/RadarChart'
import { ResultTitle } from '../components/result/ResultTitle'
import { ScoreBadge } from '../components/result/ScoreBadge'
import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { LocaleText, useLanguage } from '../context/language'
import { LoadingState } from '../components/LoadingState'
import { useLibrary } from '../context/library'
import { BiLinkExternal } from 'react-icons/bi'
import { ResultsPDF } from '../components/pdf/ResultsPDF'
import { ExportPDFButton } from '../components/result/ExportPDFButton'
import { ScoreByTheme } from '../components/result/section/ScoreByTheme'
import { OpenBadgeModal } from '../components/result/OpenBadgeModal'
import dayjs from 'dayjs'
import { ErrorState } from '../components/ErrorState'

function ResultScreen() {
  const { questionnaire } = useQuestionnaire()
  const { t } = useLanguage()

  const [resultsCorrection, setResultsCorrection] = useState(null)
  const [isLoading, setLoading] = useState(true)

  const [error, setError] = useState(null)

  const [chartImageUrl, setImageUrl] = useState(null)

  const navigate = useNavigate()
  const { library, userIsLoading } = useLibrary()

  const { id } = useParams()

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: true,
    lg: false,
    xl: false,
  })

  useEffect(() => {
    if (id && !userIsLoading) {
      const fetchAndLoadResults = async () => {
        try {
          const results = await library.QuestionnaireResult.get(id, {
            options: {
              populate: 'createdBy',
            },
          })

          setResultsCorrection(results)
          // console.log(results)
        } catch (err) {
          console.error(err)
          setError(err.error)
        } finally {
          setLoading(false)
        }
      }

      fetchAndLoadResults()
    }
  }, [id, library, userIsLoading])

  const OverallScore = ({ score, title, description, ...props }) => {
    const { isOpen, onClose, onOpen } = useDisclosure()

    return (
      <Box {...props}>
        <ResultTitle boldIndex={1} text={t('Results.Title.Overall')} />
        <Flex direction="column" align="center">
          <Text textAlign="center" fontFamily="heading" fontSize="5xl">
            {title.toUpperCase()}
          </Text>
          <ScoreBadge m="auto" score={score} />
          <Text p={5} width="80%" textAlign="center" fontFamily="normal">
            {description}
          </Text>
          {resultsCorrection.badge_assertion ? (
            <Button onClick={onOpen}>
              <LocaleText id="Button.GetYourOpenBadge" />
            </Button>
          ) : null}
          <OpenBadgeModal badgeAssertion={resultsCorrection.badge_assertion} isOpen={isOpen} onClose={onClose} />
        </Flex>
      </Box>
    )
  }

  if (isLoading || isMobile === undefined) {
    return <LoadingState mt="20vh" />
  }

  if (error) {
    return <ErrorState error={error} />
  }

  if (!questionnaire && !resultsCorrection) {
    return (
      <VStack mt={10} textAlign="center">
        <Text mb={3}>
          <LocaleText id="Results.Error.NoQuestionsAnswered" />
        </Text>
        <Button as={Link} to="/">
          <LocaleText id="Button.GoBack" />
        </Button>
      </VStack>
    )
  }

  const userText = resultsCorrection.createdBy
    ? `${resultsCorrection.createdBy.firstName} ${resultsCorrection.createdBy.lastName} (${resultsCorrection.createdBy.email})`
    : t('Results.Text.Anonymous')

  const finishedOnText = `${t('Results.Text.FinishedOn')} ${dayjs(resultsCorrection.end_time).format(
    'DD/MM/YYYY HH:mm',
  )} ${t('Results.Text.By')} ${userText}`

  return (
    <>
      <Flex pb={20} alignItems="center" direction="column" justify="center">
        <Box w={['98%', '95%', '75%', '50%']} mt={3}>
          <Text textAlign="center" mt={3} color="rgba(35, 52, 129, 0.75)" fontFamily="light" fontSize="xs">
            {finishedOnText}
          </Text>
          <OverallScore
            overflow={['hidden', 'hidden', 'visible', 'visible']}
            title={t(`Text.${resultsCorrection?.rank}`)}
            description={t('Results.Description')}
            score={resultsCorrection.score_percentage}
          />

          <ScoreByTheme
            resultsCorrection={resultsCorrection}
            overflow={['hidden', 'hidden', 'visible', 'visible']}
            mb={3}
          />

          <ResultTitle
            overflow={['hidden', 'hidden', 'visible', 'visible']}
            boldIndex={1}
            text={t('Results.Title.RadarChart')}
          />
        </Box>

        <RadarChart
          maxW={['100%', '100%', '80%', '70%']}
          setImageUrl={setImageUrl}
          pt={10}
          pb={20}
          width="100%"
          labels={resultsCorrection.categories.map((category, i) => (isMobile ? `${i}` : category.title))}
          datasets={[
            {
              data: resultsCorrection.categories.map((category) => category.score_percentage),
            },
          ]}
        />
        <VStack>
          <Button
            color="blue.900"
            font="black"
            colorScheme="yellow"
            onClick={() => navigate('/')}
            text="Start again"
            my={5}
          >
            <LocaleText id="Button.StartAgain" />
          </Button>

          <ExportPDFButton
            filename="results.pdf"
            document={<ResultsPDF results={resultsCorrection} chartImage={chartImageUrl} />}
          />

          <Button
            rightIcon={
              <Box fill="blue.900">
                <BiLinkExternal size="1.3em" />
              </Box>
            }
            target="_blank"
            as="a"
            href="https://form.typeform.com/to/tfwOUgjY"
          >
            <LocaleText id="Button.Feedback" />
          </Button>
        </VStack>
      </Flex>

      {/* <RadarChart // Rendering outside of user view for the PDF, kind of a hack...
        position="absolute"
        transform="translateX(-1000%)"
        width="1800px"
        // responsive={false}
        labelFontSize={25}
        lineWidth={2}
        setImageUrl={setImageUrl}
        labels={questionnaire.categories.map((category) => category.title)}
        datasets={[
          {
            data: resultsCorrection.categories.map((category) => category.score_percentage),
          },
        ]}
      />
      // TODO : re-activate this later and find a clean solution... 
      */}
    </>
  )
}

export { ResultScreen }
