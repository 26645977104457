import { Stat, StatLabel, StatNumber } from '@chakra-ui/react'

export function StatDisplay({ label, value }) {
  return (
    <Stat>
      <StatLabel>{label}</StatLabel>
      <StatNumber>{value}</StatNumber>
    </Stat>
  )
}
