import { Button, Text, VStack } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { LocaleText } from '../context/language'

export function ErrorState({ error }) {
  return (
    <VStack mt={10} textAlign="center">
      <Text mb={3} fontFamily="bold">
        Error {error.code}
      </Text>
      <Text mb={3} fontFamily="medium">
        {error.message}
      </Text>
      <Button as={Link} to="/">
        <LocaleText id="Button.GoBack" />
      </Button>
    </VStack>
  )
}
