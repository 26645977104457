import { chakra, Flex } from '@chakra-ui/react'

export function Badge({ size, children, value, color, ...props }) {
  const content = value ? (
    <chakra.span color="white" fontSize="0.9em" fontFamily="bold">
      {value}
    </chakra.span>
  ) : (
    children
  )

  return (
    <Flex
      width={size}
      height={size}
      bg={color}
      borderRadius="3px"
      boxShadow="md"
      align="center"
      justifyContent="space-around"
      {...props}
    >
      {content}
    </Flex>
  )
}

Badge.defaultProps = {
  size: 5,
  color: 'blue.500',
}
