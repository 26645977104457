import { Box, Container, Heading, VStack } from '@chakra-ui/react'
import { UsersList } from '../../components/admin/UsersList'

export function UsersScreen() {
  return (
    <VStack mx={5}>
      <Container>
        <Heading>Users</Heading>
      </Container>
      <Box w="80%" minW={200} boxShadow="box" borderRadius="5px" p={5}>
        <UsersList />
      </Box>
    </VStack>
  )
}
