import { Center } from '@chakra-ui/react'
import { LoadingState } from './LoadingState'

export function LoadingPage({ ...props }) {
  return (
    <Center width="100%" height="100%" {...props}>
      <LoadingState />
    </Center>
  )
}
