import { FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react'
import { useField } from 'formik'
import { useEffect } from 'react'

export function InputField({ label, externalError, resetExternalError, isRequired, ...props }) {
  const [field, meta, helpers] = useField(props)

  useEffect(() => {
    helpers.setError(externalError)
    // note : This is needed because helpers functions are not memoized and changes after each render https://github.com/formium/formik/issues/2268
    // eslint-disable-next-line
  }, [externalError])

  field.onChange = (e) => {
    if (resetExternalError) resetExternalError()
    helpers.setValue(e.target.value)
  }

  return (
    <FormControl isRequired={isRequired} isInvalid={meta.error && meta.touched} id={field.name}>
      <FormLabel htmlFor={label} fontFamily="medium">
        {label}
      </FormLabel>
      <Input {...field} {...props} />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
      {/* <FormErrorMessage>{externalError}</FormErrorMessage> */}
    </FormControl>
  )
}
