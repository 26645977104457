import { Slider, SliderFilledTrack, SliderThumb, SliderTrack } from '@chakra-ui/react'

export function SliderForm({ form }) {
  return (
    <Slider defaultValue={30}>
      <SliderTrack>
        <SliderFilledTrack />
      </SliderTrack>
      <SliderThumb />
    </Slider>
  )
}
