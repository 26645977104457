import { Image, Font, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer'
import {
  GothamBlack,
  GothamBold,
  GothamBookRegular,
  GothamLight,
  GothamMedium,
  GothamThin,
  GothamUltra,
} from '../../assets/fonts'
import logo from '../../assets/img/gdpr-edu-dapro.png'
import erasmusFlag from '../../assets/img/flag-eu-scaled-4x.png'
import { customTheme } from '../../context'

Font.register({
  family: 'Gotham Ultra',
  src: GothamUltra,
})

Font.register({
  family: 'Gotham',
  fonts: [
    {
      src: GothamBookRegular,
    },
    {
      src: GothamBold,
      fontWeight: 'bold',
    },
    {
      src: GothamBlack,
      fontWeight: 900,
    },
    {
      src: GothamMedium,
      fontWeight: 500,
    },
    {
      src: GothamLight,
      fontWeight: 200,
    },
    {
      src: GothamThin,
      fontWeight: 100,
    },
  ],
})

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Gotham',
    fontSize: 12,
    color: customTheme.colors.blue[900],
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: '20mm',
    paddingTop: '25mm',
  },
  resultTitle: {
    box: {
      marginTop: 10,
      marginBottom: 10,
      width: '100%',
    },
    text: {
      fontFamily: 'Gotham',
      fontWeight: 200,
      fontSize: 22,
    },
    divider: {
      marginTop: 8,
      marginBottom: 14,
      marginLeft: 40,
      backgroundColor: customTheme.colors.yellow[500],
      width: '60%',
      height: '2pt',
    },
  },
  overallScore: {
    section: {
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      fontWeight: 'black',
      fontSize: 25,
    },
    score: {
      padding: 8,
      width: 100,
      backgroundColor: customTheme.colors.blue[900],
      color: 'white',
      borderRadius: 5,
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: 25,
      boxShadow: customTheme.shadows.box,
      margin: 15,
    },
    message: {
      width: '80%',
      textAlign: 'center',
      margin: 10,
      marginBottom: 30,
    },
  },
  littleBadge: {
    width: 16,
    height: 16,
    fontSize: 10,
    borderRadius: 3,
    backgroundColor: customTheme.colors.blue[500],
    color: 'white',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  slider: {
    topContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: 10,
      width: '100%',
      // border: '1pt solid red',
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      height: 3,
      width: '100%',
      // border: '1pt solid blue',
    },
    filled: {
      height: 3,
      flexBasis: '50%',
      backgroundColor: customTheme.colors.blue[500],
    },
    empty: {
      height: 3,
      flexBasis: '50%',
      backgroundColor: customTheme.colors.gray[400],
    },
    thumb: {
      position: 'absolute',
      width: 7,
      height: 7,
      backgroundColor: customTheme.colors.blue[500],
      borderRadius: 10,
      transform: 'translate(-3pt, 0)',
    },
  },
  category: {
    container: {
      width: '70%',
      marginBottom: 20,
    },
    topSection: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      paddingBottom: 5,
    },
    bottomSection: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    badge: {
      flexBasis: '7%',
    },
    title: {
      flexBasis: '93%',
    },
    slider: {
      flexBasis: '85%',
    },
    score: {
      fontWeight: 'bold',
      flexBasis: '10%',
    },
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
  },
  mainLogo: {
    width: '35%',
    alignSelf: 'flex-start',
  },
  erasmusLogo: {
    width: '33%',
    alignSelf: 'flex-end',
  },
  questionDetails: {
    container: {
      marginBottom: '10pt',
    },
    title: {
      fontWeight: 500,
    },
    answer: {
      marginBottom: '2pt',
    },
  },
})

function ResultTitlePDF({ children }) {
  const { resultTitle: style } = styles
  return (
    <View style={style.box}>
      <Text style={style.text}>{children}</Text>
      <View style={style.divider} />
    </View>
  )
}

const LittleBadgePDF = ({ color, number }) => (
  <View style={[styles.littleBadge, { backgroundColor: color }]}>
    <Text>{number}</Text>
  </View>
)

const SliderPDF = ({ score, color }) => {
  const thumbPos = score + '%'
  const emptyBasis = 100 - score + '%'

  const colorStyle = {
    backgroundColor: color,
  }

  return (
    <View style={styles.slider.topContainer}>
      <View style={styles.slider.container}>
        <View style={[styles.slider.filled, { flexBasis: thumbPos, ...colorStyle }]} />
        <View style={[styles.slider.empty, { flexBasis: emptyBasis }]} />
      </View>
      <View style={[styles.slider.thumb, { left: thumbPos, ...colorStyle }]}></View>
    </View>
  )
}

function CategoryScorePDF({ title, score, color, categoryNumber, ...props }) {
  return (
    <View style={styles.category.container}>
      <View style={styles.category.topSection}>
        <View style={styles.category.badge}>
          <LittleBadgePDF color={color} number={categoryNumber} />
        </View>
        <Text style={styles.category.title}>{title}</Text>
      </View>
      <View style={styles.category.bottomSection}>
        <View style={styles.category.slider}>
          <SliderPDF color={color} score={score} />
        </View>
        <Text style={styles.category.score}>{score + '%'}</Text>
      </View>
    </View>
  )
}

function QuestionDetails({ questionResult }) {
  if (questionResult.question.form.kind !== 'single' && questionResult.question.form.kind !== 'multiple') {
    return null
  }

  return (
    <View style={styles.questionDetails.container}>
      <Text style={styles.questionDetails.title}>{questionResult.question.question}</Text>
      {questionResult.isCorrect ? (
        <Text style={{ color: 'green', fontSize: '10pt' }}>(Right answer)</Text>
      ) : (
        <Text style={{ color: 'red', fontSize: '10pt' }}>(Wrong answer)</Text>
      )}
      {questionResult.answers.map((answer, i) => (
        <Text style={styles.questionDetails.answer} key={i}>
          {questionResult.question.form.choices[answer].title}
        </Text>
      ))}
    </View>
  )
}

export function ResultsPDF({ results, chartImage, ...props }) {
  const rankTitle = results.rank.toUpperCase()

  return (
    <Document title="EDU-DAPRO Quiz Results" author="EDU-DAPRO">
      <Page size="A4" style={styles.page}>
        <View style={styles.imageContainer}>
          <Image style={styles.mainLogo} src={logo} />
          <Image style={styles.erasmusLogo} src={erasmusFlag} />
        </View>
        <ResultTitlePDF>
          OVERALL <Text style={{ fontWeight: 'bold' }}>SCORE</Text>
        </ResultTitlePDF>
        <View style={styles.overallScore.section}>
          <Text style={styles.overallScore.title}>{rankTitle}</Text>
          <Text style={styles.overallScore.score}>{results.score_percentage}%</Text>
          <Text style={styles.overallScore.message}>
            Congratulations you have completed the GDPR e-test! Based on your answers, we suggest you to review some of
            the GDPR themes of the mobile application that we recommend you below.
          </Text>
        </View>
        <ResultTitlePDF>
          <Text style={{ fontWeight: 'bold' }}>SCORES</Text> BY THEME
        </ResultTitlePDF>
        {results.categories.map((category, i) => {
          let color = null
          if (category.score_percentage < 25) {
            color = customTheme.colors.red[600]
          } else if (category.score_percentage < 75) {
            color = customTheme.colors.yellow[500]
          } else {
            color = customTheme.colors.blue[500]
          }

          return (
            <CategoryScorePDF
              key={i}
              color={color}
              title={category.title}
              score={category.score_percentage}
              categoryNumber={i + 1}
            />
          )
        })}
      </Page>
      <Page size="A4" style={styles.page}>
        <ResultTitlePDF>
          RADAR <Text style={{ fontWeight: 'bold' }}>CHART</Text>
        </ResultTitlePDF>
        <View style={{ textAlign: 'center' }}>
          {chartImage ? <Image src={chartImage} /> : <Text>Error loading chart...</Text>}
        </View>
      </Page>
      <Page size="A4" wrap={true} style={styles.page}>
        <ResultTitlePDF>
          YOUR <Text style={{ fontWeight: 'bold' }}>ANSWERS</Text>
        </ResultTitlePDF>
        {results.question_results.map((result, i) => (
          <QuestionDetails key={i} questionResult={result} />
        ))}
      </Page>
    </Document>
  )
}
