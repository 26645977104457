import {
  Button,
  Container,
  FormControl,
  Link,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  VStack,
  Box,
} from '@chakra-ui/react'
import { Formik, Field } from 'formik'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import { Link as LinkRouter } from 'react-router-dom'
import * as Yup from 'yup'
import { useLanguage } from '../context/language'
import { useLibrary } from '../context/library'

export function AuthenticationScreen() {
  const { library } = useLibrary()
  const [error, setError] = useState()
  const navigate = useNavigate()

  const { t } = useLanguage()

  const handleSubmit = ({ email, password }, actions) => {
    library
      .signIn({ email, password })
      .then(() => {
        actions.setSubmitting(false)
        navigate('/')
      })
      .catch((err) => {
        actions.setSubmitting(false)
        setError(true)
      })
  }

  return (
    <Container maxW="100%">
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={Yup.object({
          email: Yup.string().email(t('Form.Email.Invalid')).required(t('Form.FieldRequired')),
          password: Yup.string().required(t('Form.FieldRequired')),
        })}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <VStack m="auto" align="center" minW="sm" width="30%" mt="20vh">
              <Field name="email">
                {({ field, form }) => (
                  <FormControl isInvalid={(form.errors.email && form.touched.email) || error} id="email">
                    <FormLabel htmlFor="email" fontFamily="medium">
                      {t('Form.Email')}
                    </FormLabel>
                    <Input {...field} id="email" type="email" placeholder={t('Form.Email.Placeholder')} />
                    <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="password">
                {({ field, form }) => (
                  <FormControl isInvalid={(form.errors.password && form.touched.password) || error} id="password">
                    <FormLabel htmlFor="password" fontFamily="medium">
                      {t('Form.Password')}
                    </FormLabel>
                    <Input {...field} id="password" type="password" placeholder={t('Form.Password.Placeholder')} />
                    <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                    <FormErrorMessage>{error && t('Auth.InvalidCredentials')}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Box>
                <Button mt={3} spacing={2} isLoading={formik.isSubmitting} type="submit">
                  {t('Button.Signin')}
                </Button>
              </Box>
              <Text>{t('Text.Or')}</Text>
              <Link fontFamily="bold" color="blue.500" as={LinkRouter} to="/signup">
                {t('Form.Link.CreateAccount')}
              </Link>
            </VStack>
          </form>
        )}
      </Formik>
    </Container>
  )
}
