import { Box, chakra } from '@chakra-ui/react'

export const UnderlineBar = ({ length, ...props }) => {
  return <Box height="2px" width={length} bgColor="yellow.500" {...props} />
}

UnderlineBar.defaultProps = {
  length: '420px',
}

export function ResultTitle({ text, boldIndex, ...props }) {
  const words = text.toUpperCase().split(' ')

  if (boldIndex < words.length && boldIndex >= 0) {
    words[boldIndex] = <chakra.span fontFamily="bold"> {words[boldIndex]} </chakra.span>
  }

  return (
    <Box boxSizing="border-box" mb={5} pt={4} pb={4} fontSize="2.5em" fontFamily="light" {...props}>
      {boldIndex === 0 ? null : words.slice(0, boldIndex).join(' ')}
      {words[boldIndex]}
      {words.slice(boldIndex + 1, words.length).join(' ')}
      <UnderlineBar ml={20} />
    </Box>
  )
}
