import { createContext, useMemo, useContext, useState, useEffect, useCallback } from 'react'
import { useLibrary } from './library'

const QuestionnaireContext = createContext()
QuestionnaireContext.displayName = 'QuestionnaireContext'

function QuestionnaireProvider(props) {
  const [questionnaire, setQuestionnaire] = useState(null)
  const [index, setIndex] = useState(0)
  const [question, setQuestion] = useState()

  // Index of first question of each theme
  const [themesIndex, setThemesIndex] = useState([])

  const { library } = useLibrary()

  const fetchQuestionnaire = useCallback(
    (language = 'en') => {
      return library.Questionnaire.getMain({
        language,
        // TODO: Uncomment after tested in production
        shuffle: true,
        questionsPerTheme: process.env.NODE_ENV === 'production' ? 5 : 2,
      }).then((questionnaire) => {
        // console.log(questionnaire)
        setQuestionnaire(questionnaire)
      })
    },
    [library.Questionnaire],
  )

  useEffect(() => {
    if (!questionnaire) {
      return
    }
    const marks = questionnaire.categories.map((category, i, categories) => {
      return {
        title: category.title,
        value:
          i === 0
            ? 0
            : categories.reduce((prev, cur, j) => {
                if (j > i || j === 0) {
                  return prev
                }

                return prev + cur.total_questions
              }, 0),
      }
    })

    setThemesIndex(marks)
  }, [questionnaire])

  useEffect(() => {
    if (questionnaire) {
      setQuestion(questionnaire.questions[index])
    }
  }, [index, questionnaire])

  const value = useMemo(
    () => ({
      themesIndex,
      fetchQuestionnaire,
      question,
      questionnaire,
      index,
      setIndex,
      setQuestionnaire,
      setQuestion,
    }),
    [questionnaire, themesIndex, question, index, setIndex, fetchQuestionnaire],
  )

  return <QuestionnaireContext.Provider value={value} {...props} />
}

function useQuestionnaire() {
  return useContext(QuestionnaireContext)
}

export { QuestionnaireProvider, useQuestionnaire }
