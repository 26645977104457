import { ChakraProvider } from '@chakra-ui/react'
import { BrowserRouter } from 'react-router-dom'
import { LanguageProvider } from './language'
import { QuestionnaireProvider } from './questionnaire'
import { ScoreProvider } from './score'
import { extendTheme } from '@chakra-ui/react'
import { LibraryProvider } from './library'

export const customTheme = extendTheme({
  fonts: {
    heading: 'Gotham Black',
    ultra: 'Gotham Ultra',
    body: 'Gotham Book Regular',
    normal: 'Gotham Book Regular',
    black: 'Gotham Black',
    bold: 'Gotham Bold',
    light: 'Gotham light',
    thin: 'Gotham Thin',
    medium: 'Gotham Medium',
  },
  shadows: {
    md: '0px 3px 15px rgba(0, 0, 0, 0.3)',
    box: '0px 2px 4px rgba(0, 0, 0, 0.4)',
  },
  colors: {
    blue: {
      500: '#3659F8',
      400: '#6374FF',
      300: '#8990FF',
      200: '#ACADFF',
      100: '#CFCBFF',
      900: '#233481',
      scoreBg: '#2640B5',
    },
    yellow: {
      500: '#F8D51D',
      gradient: 'linear-gradient(173deg, rgba(255,220,37,1) 56%, rgba(255,233,85,1) 100%)',
    },
  },
})

function AppProviders({ children }) {
  return (
    <BrowserRouter>
      <ChakraProvider theme={customTheme}>
        <LibraryProvider>
          <QuestionnaireProvider>
            <ScoreProvider>
              <LanguageProvider>{children}</LanguageProvider>
            </ScoreProvider>
          </QuestionnaireProvider>
        </LibraryProvider>
      </ChakraProvider>
    </BrowserRouter>
  )
}

export { AppProviders }
