import { Box, Button, Center, Divider, Heading } from '@chakra-ui/react'
import { useCallback, useState } from 'react'
import { LocaleText } from '../../context/language'
import { useQuestionnaire } from '../../context/questionnaire'
import { createQuestionResult, useScore } from '../../context/score'
import { CheckboxForm } from './CheckboxForm'
import { RadioForm } from './RadioForm'
import { SliderForm } from './SliderForm'
import { useNavigate, useParams } from 'react-router-dom'
import { LinkForm } from './LinkForm'
import { useReset } from '../../hooks/useReset'

export function Form({ form }) {
  let formComponent

  const { question, questionnaire, index } = useQuestionnaire()
  const { setCurrentResult, results, postCurrentResults } = useScore()
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const [submitDisabled, setSubmitDisabled] = useState(false)

  const [startTime, setStartTime] = useState(new Date())

  const [isSubmitting, setIsSubmitting] = useState(false)

  const [formValues, setFormValues] = useState()
  const reset = useReset()

  // const { questionNavigate } = useQuestionNavigator()
  const { questionIndex } = useParams()
  const navigate = useNavigate()

  const handleChange = useCallback(
    (value) => {
      setFormValues(value)
    },
    [setFormValues],
  )

  const handleSubmit = (e) => {
    e.preventDefault()

    const questionResult = createQuestionResult({
      question: question._id,
      kind: question.form.kind,
      start_time: startTime,
      end_time: new Date(),
      ...formValues,
    })

    setCurrentResult(questionResult)
    setHasSubmitted(true)
  }

  const nextQuestion = () => {
    // TODO : helper hook does not work because lastIndex is not changed, find better design
    // questionNavigate((prev) => prev + 1)

    navigate(`/questions/${parseInt(questionIndex) + 1}`)
    setHasSubmitted(false)
    setStartTime(new Date())
  }

  const passedProps = {
    handleChange,
    form,
    question,
    hasSubmitted,
    setHasSubmitted,
    setSubmitDisabled,
    key: question._id, // To reset child state
  }

  if (form.kind === 'single') {
    formComponent = <RadioForm {...passedProps} />
  } else if (form.kind === 'multiple') {
    formComponent = <CheckboxForm {...passedProps} />
  } else if (form.kind === 'slider') {
    formComponent = <SliderForm {...passedProps} />
  } else if (form.kind === 'link') {
    formComponent = <LinkForm {...passedProps} />
  }

  let nextButton

  const handleFinish = async () => {
    setIsSubmitting(true)
    const results = await postCurrentResults()
    setIsSubmitting(false)
    reset()
    navigate(`/results/${results.id_count}`)
  }

  if (questionnaire?.questions.length - 1 === index) {
    nextButton = (
      <Button
        isDisabled={results.some((r) => !r) || isSubmitting}
        isLoading={isSubmitting}
        size="lg"
        onClick={handleFinish}
      >
        <LocaleText id="Button.Finish" />
      </Button>
    )
  } else {
    nextButton = <ContinueButton onClick={nextQuestion} />
  }

  const button = !hasSubmitted ? <SubmitButton disabled={submitDisabled} /> : nextButton

  return (
    <form onSubmit={handleSubmit}>
      <Box minHeight="30vh">
        <Heading fontFamily="bold" as="h3" size="md">
          {question.question}
        </Heading>
        <Divider borderRadius="3px" borderWidth="1px" opacity={1} my="6" borderColor="yellow.500" />
        {formComponent}
      </Box>
      <Center my={10}>{button}</Center>
    </form>
  )
}

function SubmitButton({ disabled }) {
  return (
    <Button colorScheme="yellow" size="lg" isDisabled={disabled} type="submit">
      <LocaleText id="Button.Submit" />
    </Button>
  )
}

function ContinueButton({ onClick }) {
  return (
    <Button onClick={onClick} size="lg">
      <LocaleText id="Button.Continue" />
    </Button>
  )
}
