import { Box, Button, Container, Heading, Link, Text, VStack } from '@chakra-ui/react'
import { Formik } from 'formik'
import { Link as LinkRouter } from 'react-router-dom'
import { InputField } from '../components/InputField'
import * as Yup from 'yup'
import { useLibrary } from '../context/library'
import { useNavigate } from 'react-router'
import { useState } from 'react'
import { useLanguage } from '../context/language'

export function SignupScreen() {
  const { library } = useLibrary()
  const navigate = useNavigate()

  const { t } = useLanguage()

  const [emailError, setEmailError] = useState(null)

  const handleSubmit = (values, actions) => {
    const user = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      password: values.password,
      isAdmin: false,
    }

    library
      .signUp(user)
      .then(() => {
        actions.setSubmitting(false)
        navigate('/')
      })
      .catch((err) => {
        if (err.error?.name === 'HttpConflict') {
          setEmailError(t('Form.Email.AlreadyExists'))
        }

        actions.setSubmitting(false)
      })
  }

  return (
    <Container maxW="100%">
      <Box m="auto" minWidth="50%" maxW="sm" textAlign="center" py={10}>
        <Heading fontFamily="bold">{t('Form.CreateAccount.Title')}</Heading>
        <Text>{t('Form.CreateAccount.Description')}</Text>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            passwordConfirmation: '',
          }}
          validationSchema={Yup.object({
            firstName: Yup.string().required(t('Form.FieldRequired')),
            lastName: Yup.string().required(t('Form.FieldRequired')),
            email: Yup.string().email('Invalid e-mail address').required(t('Form.FieldRequired')),
            password: Yup.string().min(7, t('Form.Password.Criteria')).required(t('Form.FieldRequired')),
            passwordConfirmation: Yup.string()
              .oneOf([Yup.ref('password')], t('Form.Password.MustMatch'))
              .required(t('Form.FieldRequired')),
          })}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <VStack m="auto" align="center" minW="sm" width="30%" mt={10}>
                <InputField
                  isRequired
                  name="firstName"
                  type="text"
                  label={t('Form.FirstName')}
                  placeholder={t('Form.FirstName.Placeholder')}
                />
                <InputField
                  isRequired
                  name="lastName"
                  type="text"
                  label={t('Form.LastName')}
                  placeholder={t('Form.LastName.Placeholder')}
                />
                <InputField
                  isRequired={true}
                  externalError={emailError}
                  resetExternalError={() => setEmailError(null)}
                  name="email"
                  type="email"
                  label={t('Form.Email')}
                  placeholder={t('Form.Email.Placeholder')}
                />
                <InputField
                  isRequired
                  name="password"
                  type="password"
                  label={t('Form.Password')}
                  placeholder={t('Form.Password.Placeholder')}
                />
                <InputField
                  isRequired
                  name="passwordConfirmation"
                  type="password"
                  label={t('Form.PasswordConfirm')}
                  placeholder={t('Form.PasswordConfirm.Placeholder')}
                />
                <Button mt={4} isLoading={formik.isSubmitting} type="submit">
                  {t('Button.Signup')}
                </Button>
                <Text>{t('Text.Or')}</Text>
                <Link fontFamily="bold" color="blue.500" as={LinkRouter} to="/signin">
                  {t('Form.Link.AlreadyHaveAccount')}
                </Link>
              </VStack>
            </form>
          )}
        </Formik>
      </Box>
    </Container>
  )
}
