import { Center, Text, VStack } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useLanguage } from '../context/language'
import { useLibrary } from '../context/library'
import { LoadingState } from './LoadingState'
import { ResultPreviewEntry } from './result/ResultPreviewEntry'

export function ResultHistory({ userId, ...props }) {
  const [results, setResults] = useState(true)
  const [isLoading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const { library } = useLibrary()

  const { t } = useLanguage()

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const userResults = await library.QuestionnaireResult.find({
          createdBy: userId,
          options: {
            limit: 50,
            sort: '-end_time',
          },
          select: 'end_time score_percentage score id_count badge_assertion',
        })

        setResults(userResults)
      } catch (err) {
        setError(err.error)
      } finally {
        setLoading(false)
      }
    }

    fetchResults()
  }, [userId, library])

  if (error) {
    return <Center fontFamily="bold">Error {error?.code}</Center>
  }

  if (isLoading) {
    return <LoadingState />
  }

  return (
    <VStack width="80%" padding={3} borderRadius={5} boxShadow="box" {...props}>
      <Text textAlign="center" pb={3} fontSize="lg" alignSelf="center" fontFamily="bold">
        {t('History.PreviousResults')}
      </Text>
      {isLoading ? (
        <LoadingState py={20} />
      ) : results.length === 0 ? (
        <Text textAlign="center" px={2} py={20} fontFamily="medium" fontSize="xl">
          {t('History.NoResults')}
        </Text>
      ) : (
        results.map((results) => (
          <ResultPreviewEntry key={results.id} results={results} />
          // <Box key={results.id}>{results.id}</Box>
        ))
      )}
    </VStack>
  )
}
