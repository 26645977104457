import { Table, Thead, Tbody, Tr, Th, Td, Link } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useLibrary } from '../../context/library'
import { Link as LinkRouter } from 'react-router-dom'

export function UsersList() {
  const { library, user } = useLibrary()

  const [users, setUsers] = useState([])

  useEffect(() => {
    if (user) {
      library.User.find({ select: '-password' }).then((result) => setUsers(result))
    } else {
      // TODO: Handle if no user is connected or if not admin
    }
  }, [user, library])

  return (
    <Table size="sm" variant="simple">
      <Thead>
        <Tr>
          <Th>ID</Th>
          <Th>First Name</Th>
          <Th>Last Name</Th>
          <Th>E-mail</Th>
          <Th>Created at</Th>
        </Tr>
      </Thead>
      <Tbody>
        {users.map((user) => (
          <Tr key={user._id}>
            <Td isNumeric>
              <Link as={LinkRouter} to={`/user/${user.id_count}`}>
                {user.id_count}
              </Link>
            </Td>
            <Td>{user.firstName}</Td>
            <Td>{user.lastName}</Td>
            <Td>{user.email}</Td>
            <Td>{dayjs(user.createdAt).format('DD/MM/YYYY HH:mm:ss')}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}
