import { Box, HStack } from '@chakra-ui/react'
import { Route, Routes } from 'react-router'
import { SectionsList } from '../components/admin/SectionsList'
import { AdminResultsScreen } from './admin/AdminResultsScreen'
import { OverviewScreen } from './admin/OverviewScreen'
import { UsersScreen } from './admin/UsersScreen'

const sections = [
  {
    path: 'overview',
    textCode: 'Admin.Section.Overview',
    component: <OverviewScreen />,
  },
  {
    path: 'users',
    textCode: 'Admin.Section.Users',
    component: <UsersScreen />,
  },
  {
    path: 'results',
    textCode: 'Admin.Section.Results',
    component: <AdminResultsScreen />,
  },
]

export function AdminDashboardScreen() {
  return (
    <HStack spacing={0} width="100%">
      <SectionsList sections={sections} />
      <Box py={10} width="100%" minHeight="100vh">
        <Routes>
          {sections.map((section) => (
            <Route key={section.path} path={section.path} element={section.component} />
          ))}
        </Routes>
      </Box>
    </HStack>
  )
}
