import { useCallback } from 'react'
import { useQuestionnaire } from '../context/questionnaire'
import { useScore } from '../context/score'

/**
 * Resets all questionnaires and result of a questionnaire
 */
export function useReset() {
  const { setIndex, setQuestionnaire, setQuestion } = useQuestionnaire()
  const { setResults, setLastIndex } = useScore()

  const reset = useCallback(() => {
    setIndex(0)
    setLastIndex(0)
    setResults([])
    setQuestion(null)
    setQuestionnaire(null)
  }, [setIndex, setLastIndex, setResults, setQuestionnaire, setQuestion])

  return reset
}
