import { Fade, Heading, VStack } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { ProgressBar } from '../components/ProgressBar'
import { useQuestionnaire } from '../context/questionnaire'
import { useScore } from '../context/score'
import { useQuestionNavigator } from '../hooks/useQuestionNavigator'

export function ProgressIndicator({ ...props }) {
  const { index, questionnaire, question, themesIndex } = useQuestionnaire()
  const { lastIndex } = useScore()
  const max = questionnaire?.questions?.length

  const { questionNavigate } = useQuestionNavigator()

  const [marksProps, setMarksProps] = useState([])

  useEffect(() => {
    if (!themesIndex.length === 0) {
      return
    }

    // This is in a different useEffect to avoid computing marks value at each render
    // because lastIndex changes every step
    const marksProps = themesIndex.map((mark, i) => {
      const isAnswered = lastIndex >= mark.value
      return {
        size: isAnswered ? 14 : 10,
        bgColor: isAnswered ? 'yellow.500' : 'gray.300',
        borderColor: isAnswered ? 'blue.500' : undefined,
        borderWidth: isAnswered ? '2px' : 0,
      }
    })

    setMarksProps(marksProps)
  }, [lastIndex, themesIndex])

  return (
    <VStack pb={5} width="40%" {...props}>
      <Fade in={!!question}>
        <Heading textAlign="center" h="3.5em" fontFamily="black" fontSize="2xl">
          {question?.category || '...'}
        </Heading>
      </Fade>

      {themesIndex.length > 0 ? (
        <ProgressBar
          isReadOnly={false}
          hidden={!question}
          width="100%"
          value={index}
          onChange={(val) => {
            questionNavigate(val)
          }}
          max={max}
          thumbSize="10px"
          barHeight={1}
          marks={themesIndex}
          marksProps={marksProps}
        />
      ) : null}
    </VStack>
  )
}
