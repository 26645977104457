import { render } from 'react-dom'
import { App } from './app'
import { AppProviders } from './context'
import './index.css'

render(
  <AppProviders>
    <App />
  </AppProviders>,
  document.getElementById('root'),
)
