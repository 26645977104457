import {
  Button,
  Center,
  chakra,
  Icon,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import logo from '../../assets/img/drawing-1.svg'
import { CopyBox } from '../CopyBox'
import { BiLinkExternal } from 'react-icons/bi'
import { useLanguage } from '../../context/language'

export function OpenBadgeModal({ badgeAssertion, ...props }) {
  const { t } = useLanguage()

  return (
    <Modal size="2xl" {...props}>
      <ModalOverlay />
      <ModalContent bg="yellow.gradient" boxShadow="md">
        <ModalHeader mt={9} textAlign="center" fontFamily="heading" color="blue.900" fontSize="4xl">
          {t('OpenBadgeModal.Title')}
        </ModalHeader>
        <ModalBody color="blue.900" px={10}>
          <Center bgColor="white" m="auto" borderRadius="100%" width="300px" height="300px">
            <Image w={['70%', '60%', '60%', '60%']} src={logo} />
          </Center>
          <Text textAlign="center" my={10}>
            {t('OpenBadgeModal.Description.Part1')}
            <chakra.span fontFamily="medium"> {badgeAssertion?.recipient?.plaintextIdentity}. </chakra.span>
            {t('OpenBadgeModal.Description.Part2')}
          </Text>
          <CopyBox my={3} text={badgeAssertion?.openBadgeId} />
          <Text textAlign="center">
            <Link href={badgeAssertion?.openBadgeId} target="_blank" color="blue.500" fontFamily="medium">
              {t('OpenBadgeModal.OpenPage')} <Icon as={BiLinkExternal} fontSize="1.3em" />
            </Link>
          </Text>
        </ModalBody>
        <ModalFooter justifyContent="center">
          <Button onClick={props.onClose}>{t('Button.Close')}</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
