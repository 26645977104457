import { Box, Button } from '@chakra-ui/react'
import { MdArrowBack, MdArrowForward } from 'react-icons/md'

export function NavButton({ back, forward, ...props }) {
  let icon = forward ? MdArrowForward : MdArrowBack

  return (
    <Button borderRadius="100%" w={10} h={10} p={0} variant="ghost" {...props}>
      <Box size={25} as={icon} color="blue.900" />
    </Button>
  )
}

NavButton.defaultProps = {
  forward: false,
}
