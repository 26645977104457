import { createContext, useMemo, useContext, useState, useEffect, useCallback } from 'react'
import { useLibrary } from './library'
import { useQuestionnaire } from './questionnaire'

const ScoreContext = createContext()
ScoreContext.displayName = 'ScoreContext'

function ScoreProvider(props) {
  const { index, questionnaire } = useQuestionnaire()

  const { library } = useLibrary()

  // Array of QuestionResult object model
  const [results, setResults] = useState([])

  // Corresponds to the last question index user has been to.
  // Used for tracking last question possibly accessible
  const [lastIndex, setLastIndex] = useState(0)

  const [startTime, setStartTime] = useState()

  useEffect(() => {
    // We still initialize all values to null because for checking every answers
    // are non null with Array.prototype.every method
    const initialResultArray = questionnaire ? new Array(questionnaire.questions.length).fill(null) : []

    setResults(initialResultArray)
  }, [questionnaire])

  useEffect(() => {
    setLastIndex((lastIndex) => Math.max(index, lastIndex))
  }, [index])

  const setCurrentResult = useCallback(
    (questionResult) => {
      setResults((results) => {
        const newResults = [...results]
        newResults[index] = questionResult
        return newResults
      })
    },
    [index],
  )

  const postCurrentResults = useCallback(async () => {
    const questionResults = await library.QuestionResult.bulkCreate(results)

    const result = await library.QuestionnaireResult.create({
      questionnaire: questionnaire._id,
      start_time: startTime,
      end_time: new Date(),
      question_results: questionResults.results.map((r) => r._id),
    })

    return result
  }, [questionnaire, results, startTime, library])

  const currentResult = results[index]

  const value = useMemo(
    () => ({
      lastIndex,
      setLastIndex,
      currentResult,
      setCurrentResult,
      setResults,
      results,
      startTime,
      setStartTime,
      postCurrentResults,
    }),
    [currentResult, setResults, setCurrentResult, results, lastIndex, setLastIndex, startTime, postCurrentResults],
  )
  return <ScoreContext.Provider value={value} {...props} />
}

function createQuestionResult({ question, kind, answers, start_time, end_time }) {
  return {
    question,
    kind,
    answers,
    start_time,
    end_time,
  }
}

function useScore() {
  return useContext(ScoreContext)
}

export { ScoreProvider, useScore, createQuestionResult }
