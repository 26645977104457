import { IconButton, Input, InputGroup, InputRightAddon, useClipboard } from '@chakra-ui/react'
import { MdContentCopy, MdCheck } from 'react-icons/md'

export function CopyBox({ text = '', ...props }) {
  const { hasCopied, onCopy } = useClipboard(text)
  return (
    <InputGroup variant="filled" {...props}>
      <Input
        _focus={{
          border: '2px solid',
          borderRightWidth: '3px',
          borderColor: 'blue.500',
          background: 'white',
        }}
        boxSizing="border-box"
        fontFamily="medium"
        borderRadius="10px"
        variant="filled"
        bgColor="white"
        isReadOnly
        value={text}
      />
      {/* <InputRightElement borderRadius="15px">Copy</InputRightElement> */}
      <InputRightAddon
        as={IconButton}
        fontSize="20px"
        color="blue.900"
        onClick={onCopy}
        icon={hasCopied ? <MdCheck /> : <MdContentCopy />}
        borderLeft="1px solid rgba(255,255,255, 0)"
        // borderRadius="10px"
        size="lg"
        _hover={{
          boxShadow: 'inset 0 0 100px 100px rgba(255, 255, 255, 0.1)',
        }}
      ></InputRightAddon>
    </InputGroup>
  )
}
