import { Box, Container, Heading, HStack, Text, VStack } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { LoadingPage } from '../components/LoadingPage'
import { useLanguage } from '../context/language'
import { useLibrary } from '../context/library'
import { AccountCircle } from '../assets/img/account_circle'
import { ResultHistory } from '../components/ResultHistory'
import { ErrorState } from '../components/ErrorState'

export function SingleUserScreen() {
  const { id } = useParams()
  const { t } = useLanguage()
  const { library, userIsLoading } = useLibrary()
  const [isLoading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const [pageUser, setPageUser] = useState(null)

  useEffect(() => {
    const fetchAndLoadResult = async () => {
      try {
        const fetchedUser = await library.User.get(id)
        setPageUser(fetchedUser)
      } catch (err) {
        setError(err.error)
      } finally {
        setLoading(false)
      }
    }
    if (id && !userIsLoading) {
      fetchAndLoadResult()
    }
  }, [id, userIsLoading, library])

  if (error) {
    return <ErrorState error={error} />
  }

  if (isLoading) {
    return <LoadingPage />
  }

  return (
    <Container py={20}>
      <VStack>
        <HStack>
          <Box w={150} h={150} fill="blue.900" as={AccountCircle} />
          <VStack align="left">
            <Heading as="h5" size="lg" fontFamily="bold">
              {`${pageUser.firstName} ${pageUser.lastName}`}
            </Heading>
            {pageUser.isAdmin && (
              <Text color="red.600" fontFamily="medium">
                {t('Text.Admin')}
              </Text>
            )}
            <Text fontFamily="normal">{pageUser.email}</Text>
          </VStack>
        </HStack>

        <ResultHistory userId={pageUser._id} />
      </VStack>
    </Container>
  )
}
