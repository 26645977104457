import { createContext, useContext, useState, useMemo, useEffect } from 'react'
import { Library } from '../services'

const LibraryContext = createContext()
LibraryContext.displayName = 'LibraryContext'

function LibraryProvider(props) {
  const [library] = useState(new Library())

  const [user, setUser] = useState(null)
  const [userIsLoading, setUserIsLoading] = useState(true)

  useEffect(() => {
    const observer = async (token) => {
      if (token) {
        localStorage.setItem('accessToken', token)

        setUserIsLoading(true)
        const user = await library.User.getMe()
        setUser(user)
      } else {
        localStorage.removeItem('accessToken')

        setUser(null)
      }
      setUserIsLoading(false)
    }

    library.subscribeAuth(observer)

    // Triggers the observer to load initial state
    library.accessToken = localStorage.getItem('accessToken')

    return () => library.unsubscribeAuth(observer)
  }, [library])

  const value = useMemo(
    () => ({
      library,
      user,
      userIsLoading,
    }),
    [library, user, userIsLoading],
  )

  return <LibraryContext.Provider value={value} {...props} />
}

function useLibrary() {
  return useContext(LibraryContext)
}

export { LibraryProvider, useLibrary }
