import { useNavigate, useParams } from 'react-router'
import { useQuestionnaire } from '../context/questionnaire'
import { useScore } from '../context/score'

/**
 * Helper Hook used to navigate between different questions routes.
 * This hook should be used whenever possible in order to navigate
 * between different questions because it performs index validation.
 *
 * Callback function for returned `questionNavigate` function only works inside /questions/:questionIndex route
 * @return {Object} questionNavigators
 * @return {Function} questionNavigators.questionNavigate Function used to navigate to question index
 * @return {Function} questionNavigators.questionIsNavigable Function returning a boolean telling whether or not a question is navigable.
 */
export function useQuestionNavigator() {
  const { questionnaire } = useQuestionnaire()
  const { lastIndex } = useScore()
  const navigate = useNavigate()

  const { questionIndex } = useParams()

  const questionNavigate = (navigateTo) => {
    let i

    if (typeof navigateTo === 'function') {
      if (!questionIndex) {
        console.error(
          'useQuestionNavigator: Using function callback inside questionNavigate while not inside /questions/:questionIndex route',
        )
        return
      }

      if (isNaN(questionIndex)) {
        console.error('useQuestionNavigator: questionIndex param is not a number')
        return
      }

      i = navigateTo(parseInt(questionIndex))
    } else if (typeof navigateTo === 'number') {
      i = navigateTo
    } else {
      console.error('useQuestionNavigator: Invalid argument')
      return
    }

    if (i < 0 || i > questionnaire.length - 1) {
      console.warn('useQuestionNavigator: Tried to access invalid question index')
      return
    } else if (i > lastIndex) {
      console.warn(`useQuestionNavigator: Tried to access unaswered question (${i}) other than last one (${lastIndex})`)
      return
    }
    navigate(`/questions/${i}`)
  }

  const questionIsNavigable = (i) => {
    return i >= 0 && i <= lastIndex
  }

  return {
    questionNavigate,
    questionIsNavigable,
  }
}
