import { HStack, Tooltip, Box } from '@chakra-ui/react'
import { useLanguage } from '../context/language'
import FRFlag from '../assets/img/flag-fr.png'
import UKFlag from '../assets/img/flag-uk.png'
import PLFlag from '../assets/img/flag-pl.png'
import ATFlag from '../assets/img/flag-at.png'

export function LanguagePicker({ ...props }) {
  const { language, setLanguage } = useLanguage()
  const width = '45px'

  const getOpacity = (newLanguage) => (newLanguage === language ? 1 : 0.2)

  const languageData = [
    {
      img: FRFlag,
      alt: 'fr-flag',
      code: 'fr',
      disabled: false,
      tooltip: 'Français',
    },
    {
      img: UKFlag,
      alt: 'uk-flag',
      code: 'en', // Following language ISO-639-1 codes
      disabled: false,
      tooltip: 'English',
    },
    {
      img: PLFlag,
      alt: 'pl-flag',
      code: 'pl',
      disabled: false,
      tooltip: 'Polskie',
    },
    {
      img: ATFlag,
      alt: 'de-flag',
      code: 'de',
      disabled: false,
      tooltip: 'Deutsche',
    },
  ]

  return (
    <HStack justifyContent="center" spacing={5} {...props}>
      {languageData.map((language) => {
        return (
          <Tooltip key={language.code} label={language.tooltip} aria-label={language.tooltip}>
            <Box
              opacity={() => getOpacity(language.code)}
              disabled={language.disabled}
              as="button"
              onClick={() => setLanguage(language.code)}
            >
              <img width={width} alt={language.alt} src={language.img} />
            </Box>
          </Tooltip>
        )
      })}
    </HStack>
  )
}
