import { Box, Text, VStack } from '@chakra-ui/react'
import { Link, useLocation } from 'react-router-dom'
import { useLanguage } from '../../context/language'

const SectionElement = ({ text, path, isActive = false }) => {
  return (
    <Box
      px={6}
      py={3}
      width="100%"
      _hover={{
        bgColor: isActive ? null : 'rgba(255, 255, 255, 0.1)',
      }}
      bgColor={isActive ? 'blue.500' : null}
      as={Link}
      to={path}
    >
      <Text fontFamily="medium" color="white">
        {text}
      </Text>
    </Box>
  )
}

export function SectionsList({ sections, ...props }) {
  const { t } = useLanguage()
  const location = useLocation()

  const [currentSectionPath] = location.pathname.split('/').slice(-1)

  return (
    <VStack py={10} spacing={0} height="100%" w={60} bgColor="blue.900" {...props}>
      {sections.map((section) => (
        <SectionElement
          key={section.path}
          text={t(section.textCode)}
          path={section.path}
          isActive={currentSectionPath === section.path}
        />
      ))}
    </VStack>
  )
}
