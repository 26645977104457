import { Box, useTheme } from '@chakra-ui/react'
import Chart from 'chart.js'
import { useEffect, useRef } from 'react'

const datasetOptions = {
  pointRadius: 0,
  borderWidth: 2,
  lineTension: 0.1,
  backgroundColor: 'rgba(54,89,248,0.8)',
  hoverBackgroundColor: 'rgba(54,89,248,0.1)',
  borderCapStyle: 'round',
}

export function createRadarChart(
  canvas,
  { responsive = true, labels, datasets, onComplete, fontSize = 12, lineWidth = 1, setImageUrl },
) {
  const canvasContext = canvas.getContext('2d')

  return new Chart(canvasContext, {
    type: 'radar',
    data: {
      labels,
      datasets: datasets.map((dataset) => ({
        ...datasetOptions,
        ...dataset,
      })),
    },
    options: {
      responsive,
      // devicePixelRatio: 1,
      animation: {
        onComplete: function () {
          if (setImageUrl) setImageUrl(this.toBase64Image())
        },
      },
      scale: {
        gridLines: {
          circular: true,
          lineWidth,
        },
        pointLabels: {
          fontSize,
        },
        ticks: {
          min: 0,
          max: 100,
          stepSize: 100 / 5,
          fontSize: Math.ceil(fontSize * 0.8),
        },
      },
      legend: {
        display: false,
      },
    },
  })
}

export function RadarChart({ setRef, responsive, labels, datasets, labelFontSize, lineWidth, setImageUrl, ...props }) {
  const theme = useTheme()

  Chart.defaults.global.defaultFontFamily = theme.fonts.normal

  const chartRef = useRef(null)

  useEffect(() => {
    createRadarChart(chartRef.current, {
      responsive,
      labels,
      datasets,
      lineWidth,
      fontSize: labelFontSize,
      setImageUrl,
    })

    if (setRef) {
      setRef(chartRef)
    }
  }, [datasets, responsive, chartRef, labels, setRef, lineWidth, setImageUrl, labelFontSize])

  return (
    <Box {...props}>
      <canvas id="radarChart" ref={chartRef} />
    </Box>
  )
}
