import { Box, Container, Heading, HStack, VStack } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { ResultsList } from '../../components/admin/ResultsList'
import { StatDisplay } from '../../components/admin/stats/StatDisplay'
import { useLibrary } from '../../context/library'
import { useStats } from '../../services/stats'

export function AdminResultsScreen() {
  const statsHelper = useStats()

  const [stats, setStats] = useState({})
  const { userIsLoading } = useLibrary()

  useEffect(() => {
    const fetch = async () => {
      const totalResults = await statsHelper.getTotalNumberOfResults()

      const lastWeekCount = await statsHelper.getNumberOfResultsInPeriod(
        dayjs().subtract(7, 'day').toISOString(),
        dayjs().toISOString(),
      )

      const lastMonthCount = await statsHelper.getNumberOfResultsInPeriod(
        dayjs().subtract(30, 'day').toISOString(),
        dayjs().toISOString(),
      )

      setStats((prev) => ({ ...prev, totalResults, lastWeekCount, lastMonthCount }))
    }
    if (!userIsLoading) fetch()
  }, [statsHelper, userIsLoading])

  return (
    <VStack spacing={10}>
      <Container>
        <Heading>Results</Heading>
        <HStack my={10} boxShadow="box" spacing={5} padding={3} borderRadius={5}>
          <StatDisplay label="Total answers" value={stats.totalResults || '...'} />
          <StatDisplay label="Last 30 days" value={stats.lastMonthCount || '...'} />
          <StatDisplay label="Last 7 days" value={stats.lastWeekCount || '...'} />
        </HStack>
      </Container>

      <Box mt={10} boxShadow="box" borderRadius={5} p={5}>
        <ResultsList />
      </Box>
    </VStack>
  )
}
