import { Box, Text, Tooltip } from '@chakra-ui/react'
import { LocaleText, useLanguage } from '../../context/language'
import { UnderlineBar } from './ResultTitle'
import { ReactComponent as InfoLogo } from '../../assets/img/info_outlined.svg'

export function RecommendationBox({ children, ...props }) {
  const { t } = useLanguage()

  return (
    <Box
      textAlign="center"
      fontSize="1.4em"
      fontFamily="normal"
      padding={5}
      pt={8}
      pb={8}
      width={['100%']}
      maxWidth="250px"
      minHeight="150px"
      boxShadow="box"
      borderRadius="5px"
      position="relative"
      {...props}
    >
      <Tooltip hasArrow placement="bottom" label={t('Results.Recommendation.Tooltip')}>
        <Box display="inline" fill="yellow.500" transform="scale(0.85)" position="absolute" bottom={3} right={3}>
          <InfoLogo />
        </Box>
      </Tooltip>
      <Text>
        <LocaleText id="Results.Title.Recommendation" />
      </Text>
      <UnderlineBar width="50%" m="auto" mt={1} mb={1} />
      {children}
    </Box>
  )
}
