import { useEffect } from 'react'
import { useQuestionnaire } from '../context/questionnaire'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { Flex, Box, Fade } from '@chakra-ui/react'
import { useLanguage } from '../context/language'
import { Form } from '../components/questionForms/Form'
import { NavButton } from '../components/NavButton'
import { useQuestionNavigator } from '../hooks/useQuestionNavigator'
import { LoadingState } from '../components/LoadingState'
import { useScore } from '../context/score'

function QuestionScreen() {
  const { fetchQuestionnaire, question, questionnaire, index, setIndex } = useQuestionnaire()
  const { language } = useLanguage()

  const { setStartTime } = useScore()

  const { questionIndex } = useParams()
  const navigate = useNavigate()

  const { questionNavigate, questionIsNavigable } = useQuestionNavigator()

  useEffect(() => {
    // Initialize questionnaire start time
    setStartTime(new Date())
  }, [setStartTime])

  useEffect(() => {
    if (!questionnaire) {
      fetchQuestionnaire(language)
    }
  }, [fetchQuestionnaire, questionnaire, language])

  useEffect(() => {
    // Performs route validation for :questionIndex to match numbers only
    if (!isNaN(questionIndex)) {
      setIndex(parseInt(questionIndex))
    } else {
      navigate('/')
    }
  }, [questionIndex, setIndex, navigate])

  if (questionnaire?.questions.length === index) {
    return <Navigate to="/results" />
  }

  if (!question) {
    return <LoadingState mt="20vh" />
  }

  return (
    <Flex
      flex="1 1 auto"
      bgPos="right bottom"
      backgroundRepeat="no-repeat"
      backgroundImage={['none', 'none', "url('/decorator.png')", "url('/decorator.png')"]}
      justify="center"
      pb={20}
    >
      <Box>
        <NavButton
          disabled={!questionIsNavigable(index - 1)}
          onClick={() => questionNavigate((prev) => prev - 1)}
          mt={8}
          mr={[1, 2, 8, 8]}
          ml={[1, 0, 0, 0]}
        />
      </Box>
      <Box w={['90%', '90%', '60%', '50%']} mt={8}>
        <Fade in={!!question}>
          <Form form={question.form} />
        </Fade>
      </Box>
      <Box>
        <NavButton
          disabled={!questionIsNavigable(index + 1)}
          onClick={() => questionNavigate((prev) => prev + 1)}
          forward
          mt={8}
          ml={[1, 2, 8, 8]}
          mr={[1, 0, 0, 0]}
        />
      </Box>
    </Flex>
  )
}

export { QuestionScreen }
