import { createContext, useMemo, useContext, useState, useCallback } from 'react'
import languageFile from '../locale/international.json'

export const LanguageContext = createContext()
LanguageContext.displayName = 'LanguageContext'

function LanguageProvider({ defaultLanguage = 'en', ...props }) {
  const [language, _setLanguage] = useState(localStorage.getItem('language') ?? defaultLanguage)

  const setLanguage = useCallback(
    (language) => {
      _setLanguage(language)
      localStorage.setItem('language', language)
    },
    [_setLanguage],
  )

  // Returns string translation of associated id
  const t = useCallback(
    (id) => {
      return getLocaleText(id, language)
    },
    [language],
  )

  const value = useMemo(() => ({ t, language, setLanguage }), [t, language, setLanguage])
  return <LanguageContext.Provider value={value} {...props} />
}

function useLanguage() {
  return useContext(LanguageContext)
}

function getLocaleText(id, language) {
  let localeMessages

  if (languageFile[language] && languageFile[language][id]) {
    localeMessages = languageFile[language]
  } else {
    localeMessages = languageFile['en']
  }

  return localeMessages[id] || id
}

function LocaleText({ id, defaultMessage, children, ...props }) {
  const { language } = useLanguage()

  const text = getLocaleText(id, language)

  return text
}

export { LanguageProvider, useLanguage, LocaleText, getLocaleText }
