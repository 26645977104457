import { Resource, Questionnaire, User } from './resource'
import qs from 'qs'

export class Library {
  _accessToken = null
  subscribers = []

  headers = new Headers({
    'Content-Type': 'application/json',
  })

  constructor({ baseUrl = process.env.REACT_APP_API_URL, accessToken = null } = {}) {
    this.baseUrl = baseUrl
    this.accessToken = accessToken

    this.question = new Resource({ endpoint: '/api/question' }, this)
    this.questionnaire = new Questionnaire({ endpoint: '/api/questionnaire' }, this)

    this.questionResult = new Resource({ endpoint: '/api/question_result' }, this)
    this.questionnaireResult = new Resource({ endpoint: '/api/questionnaire_result' }, this)

    this.user = new User({ endpoint: '/api/user' }, this)
  }

  async fetch({ queryParams, endpoint, path = '', ...options } = {}) {
    options.headers = this.headers

    if (this.accessToken) {
      options.headers.set('Authorization', `Bearer ${this.accessToken}`)
    } else {
      options.headers.delete('Authorization')
    }

    const fetchUrl = `${this.baseUrl + endpoint}/${path}${queryParams ? `?${qs.stringify(queryParams)}` : ''}`

    const response = await fetch(fetchUrl, {
      // credentials: 'include',
      ...options,
    })

    if (!response.ok) {
      const errorPayload = await response.json()

      throw errorPayload
    }

    return await response.json()
  }

  subscribeAuth(subscriberCallback) {
    this.subscribers.push(subscriberCallback)
  }

  unsubscribeAuth(subscriberCallback) {
    this.subscribers = this.subscribers.filter((sub) => sub !== subscriberCallback)
  }

  onAuthChange(token) {
    this.subscribers.forEach((sub) => {
      sub(token)
    })
  }

  async signIn({ email, password }) {
    const options = {
      endpoint: '/signin',
      method: 'POST',
      body: JSON.stringify({
        email,
        password,
      }),
    }

    const response = await this.fetch(options)

    this.accessToken = response.token

    return response.token
  }

  async signUp(data) {
    const options = {
      endpoint: '/signup',
      method: 'POST',
      body: JSON.stringify(data),
    }

    const response = await this.fetch(options)

    this.accessToken = response.token

    return response.token
  }

  async signOut() {
    this.accessToken = null
  }

  get accessToken() {
    return this._accessToken
  }

  set accessToken(token) {
    this._accessToken = token
    this.onAuthChange(token)
  }

  get Question() {
    return this.question
  }

  get QuestionResult() {
    return this.questionResult
  }

  get Questionnaire() {
    return this.questionnaire
  }

  get QuestionnaireResult() {
    return this.questionnaireResult
  }

  get User() {
    return this.user
  }
}
