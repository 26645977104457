import { Box, Container, Heading, HStack, Text, VStack } from '@chakra-ui/react'
import { AccountCircle } from '../assets/img/account_circle'
import { LoadingPage } from '../components/LoadingPage'
import { ResultHistory } from '../components/ResultHistory'
import { useLanguage } from '../context/language'
import { useLibrary } from '../context/library'

export function UserProfileScreen() {
  const { user } = useLibrary()
  const { t } = useLanguage()

  if (!user) {
    return <LoadingPage />
  }

  return (
    <Container py={20}>
      <VStack width="100%" spacing={20}>
        <HStack>
          <Box w={150} h={150} fill="blue.900" as={AccountCircle} />
          <VStack align="left">
            <Heading as="h5" size="lg" fontFamily="bold">
              {`${user.firstName} ${user.lastName}`}
            </Heading>
            {user.isAdmin && (
              <Text color="red.600" fontFamily="medium">
                {t('Text.Admin')}
              </Text>
            )}
            <Text fontFamily="normal">{user.email}</Text>
          </VStack>
        </HStack>

        <ResultHistory userId={user._id} />
      </VStack>
    </Container>
  )
}
