import { Stack, Checkbox, Text } from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { useScore } from '../../context/score'

export function CheckboxForm({ form, hasSubmitted, setHasSubmitted, setSubmitDisabled, handleChange, question }) {
  const [values, setValues] = useState([])
  const { currentResult } = useScore()

  useEffect(() => {
    if (currentResult?.kind === 'multiple') {
      // Loads back previous answers from question result context into local state
      setValues(currentResult.answers.map((value) => value.toString()))
      setHasSubmitted(currentResult.answers.length > 0)
    } else {
      setHasSubmitted(false)
    }
  }, [currentResult, setHasSubmitted])

  useEffect(() => {
    setSubmitDisabled(values.length === 0)
  }, [values, setSubmitDisabled])

  const onChange = (e) => {
    const val = e.target.value

    setValues((prev) => {
      let newValues

      if (prev.includes(val)) {
        newValues = prev.filter((el) => el !== val)
      } else {
        newValues = [...prev, val]
      }

      handleChange({ answers: newValues.map((el) => parseInt(el)) })

      return newValues
    })
  }

  return (
    <Stack>
      {form.choices.map(({ title }, i) => {
        const styleProps = {}

        if (hasSubmitted && question.form.answers.includes(i)) {
          styleProps.fontFamily = 'bold'
          styleProps.color = 'green.500'
        }

        return (
          <Checkbox
            isChecked={values.includes(i.toString())}
            isDisabled={hasSubmitted}
            _disabled={{ cursor: 'default' }}
            key={i}
            value={i.toString()}
            onChange={onChange}
          >
            <Text {...styleProps}>{title}</Text>
          </Checkbox>
        )
      })}
    </Stack>
  )
}
