import { Box, Flex, Text } from '@chakra-ui/react'
import { useEffect, useMemo, useState } from 'react'
import { ResultTitle } from '../ResultTitle'
import { AnimatePresence, motion } from 'framer-motion'
import { CategoryScore } from '../CategoryScore'
import { RecommendationBox } from '../RecommendationBox'
import { useLanguage } from '../../../context/language'

const MotionFlex = motion.custom(Flex)

const SCORE_REVIEW_THRESHOLD = 75

export const ScoreByTheme = ({ resultsCorrection, ...props }) => {
  const [expanded, setExpanded] = useState([])
  const { t } = useLanguage()

  const [themesToReview, setThemesToReview] = useState([])
  const hasExpanded = useMemo(() => expanded.includes(true), [expanded])

  useEffect(() => {
    if (!resultsCorrection) {
      return
    }

    // console.log('Correction', resultsCorrection)

    const themesToReview = resultsCorrection.categories
      .map((category, i) => (category.score_percentage < SCORE_REVIEW_THRESHOLD ? `Theme ${i + 1}` : null))
      .filter((el) => !!el)

    setThemesToReview(themesToReview)
  }, [resultsCorrection])

  useEffect(() => {
    setExpanded(new Array(resultsCorrection?.categories.length).fill(null))
  }, [resultsCorrection?.categories.length])

  const expandPanel = (i) => {
    setExpanded((prev) => {
      const copy = [...prev]
      copy[i] = !copy[i]
      return copy
    })
  }

  const variants = {
    expanded: {
      flexBasis: '100%',
    },
    notExpanded: {
      flexBasis: '50%',
    },
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
  }

  return (
    <Box {...props}>
      <ResultTitle boldIndex={0} text={t('Results.Title.ScoresByTheme')} />
      <Flex
        justifyContent="space-between"
        alignItems="center"
        direction={['column-reverse', 'column-reverse', 'row', 'row']}
      >
        <MotionFlex
          variants={variants}
          initial="notExpanded"
          animate={hasExpanded ? 'expanded' : 'notExpanded'}
          transition={{ ease: 'easeOut', duration: 0.4 }}
          direction="column"
          flexBasis={['100%', '100%', '50%', '50%']}
        >
          {resultsCorrection.categories.map(({ title, score, total_questions }, i) => {
            return (
              <CategoryScore
                onClick={() => expandPanel(i)}
                isOpen={expanded[i] ?? false}
                // isOpen={false}
                results={resultsCorrection?.question_results.filter((r) => r.question.category === title)}
                key={i}
                categoryNumber={i + 1}
                title={title}
                score={score}
                scoreMax={total_questions}
                titleFontFamily={expanded[i] ? 'bold' : 'normal'}
              />
            )
          })}
        </MotionFlex>
        <AnimatePresence>
          {!hasExpanded ? (
            <MotionFlex
              key="recommendation-box"
              variants={variants}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ ease: 'easeIn', duration: 0.1 }}
              exit={{ opacity: 0 }}
              flexBasis="50%"
              direction="row"
              justifyContent="center"
              align="center"
            >
              <RecommendationBox>
                {themesToReview.map((theme) => (
                  <Text fontFamily="bold" fontSize="1.2rem" key={theme}>
                    {theme.toUpperCase()}
                  </Text>
                ))}
              </RecommendationBox>
            </MotionFlex>
          ) : null}
        </AnimatePresence>
      </Flex>
    </Box>
  )
}
