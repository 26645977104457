import { Badge, HStack, Link, Text, useDisclosure } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { Link as LinkRouter } from 'react-router-dom'
import { useLanguage } from '../../context/language'
import { OpenBadgeModal } from './OpenBadgeModal'

export function ResultPreviewEntry({ results, ...props }) {
  const formattedTime = dayjs(results?.end_time).format('DD/MM/YYYY HH:mm')

  const { isOpen, onClose, onOpen } = useDisclosure()
  const { t } = useLanguage()

  const badge = results?.badge_assertion ? (
    <Badge onClick={onOpen} mx={3} cursor="pointer" colorScheme="yellow">
      Open Badge
    </Badge>
  ) : null

  return (
    <>
      {results.badge_assertion && (
        <OpenBadgeModal badgeAssertion={results.badge_assertion} isOpen={isOpen} onClose={onClose} />
      )}
      <HStack
        fontSize="md"
        width="100%"
        justifyContent="flex-end"
        borderBottom="1px solid"
        borderColor="rgba(100,100,100,0.1)"
      >
        <Text fontFamily="medium" flexBasis="50%">
          {Math.floor(results.score_percentage)}/100
          {badge}
        </Text>
        <Text flexBasis="30%" fontSize="xs">
          {formattedTime}
        </Text>
        <Link
          as={LinkRouter}
          to={`/results/${results?.id_count}`}
          fontFamily="bold"
          _hover={{ color: 'yellow.500', textDecoration: 'underline' }}
          flexBasis="10%"
          fontSize="xs"
        >
          {t('History.View')}
        </Link>
      </HStack>
    </>
  )
}
