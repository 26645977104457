import { Radio, Stack, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useScore } from '../../context/score'

export function RadioForm({ form, hasSubmitted, setHasSubmitted, setSubmitDisabled, handleChange, question }) {
  const [value, setValue] = useState(null)
  const { currentResult } = useScore()

  useEffect(() => {
    if (currentResult?.kind === 'single') {
      // Loads back previous answers from question result context into local state
      setValue(currentResult.answers[0].toString())
      setHasSubmitted(currentResult.answers.length > 0)
    } else {
      setHasSubmitted(false)
    }
  }, [currentResult, setHasSubmitted])

  useEffect(() => {
    setSubmitDisabled(value === undefined || value === null)
  }, [value, setSubmitDisabled])

  const onChange = (e) => {
    setValue(e.target.value)
    handleChange({ answers: [parseInt(e.target.value)] })
  }

  return (
    <Stack>
      {form.choices.map(({ title }, i) => {
        const styleProps = {}

        if (hasSubmitted && question.form.answers.includes(i)) {
          styleProps.fontFamily = 'bold'
          styleProps.color = 'green.500'
        }

        return (
          <Radio
            isChecked={value === i.toString()}
            onChange={onChange}
            isDisabled={hasSubmitted}
            key={i}
            value={i.toString()}
          >
            <Text {...styleProps}>{title}</Text>
          </Radio>
        )
      })}
    </Stack>
  )
}
