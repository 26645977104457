import { Link, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useLanguage } from '../../context/language'
import { useLibrary } from '../../context/library'
import { Link as LinkRouter } from 'react-router-dom'

const UserLink = ({ user }) => (
  <Link as={LinkRouter} to={`/user/${user.id_count}`}>
    {user.firstName} {user.lastName}
  </Link>
)

export function ResultsList({ ...props }) {
  const { t } = useLanguage()
  const [results, setResults] = useState([])
  const { library, user } = useLibrary()

  useEffect(() => {
    if (user) {
      library.QuestionnaireResult.find({
        options: {
          sort: '-end_time',
          populate: 'createdBy',
        },
        select: 'id_count createdBy end_time score_percentage score_percentage time_elapsed',
      }).then((results) => {
        setResults(results)
      })
    }
  }, [user, library])

  return (
    <Table size="sm" {...props}>
      <Thead>
        <Tr>
          <Th>{t('Text.Id')}</Th>
          <Th>{t('Text.User')}</Th>
          <Th>{t('Form.Email')}</Th>
          <Th>{t('Text.Score')}</Th>
          <Th>{t('Text.Duration')}</Th>
          <Th>{t('Text.FinishedAt')}</Th>
        </Tr>
      </Thead>
      <Tbody>
        {results.map((result) => (
          <Tr key={result._id}>
            <Td>
              <Link as={LinkRouter} to={`/results/${result.id_count}`}>
                {result.id_count}
              </Link>
            </Td>
            <Td>{result.createdBy ? <UserLink user={result.createdBy} /> : 'Anonymous'}</Td>
            <Td>{result.createdBy?.email}</Td>
            <Td>{result.score_percentage} / 100</Td>
            <Td>
              {result.time_elapsed}
              {result.time_elapsed && 's'}
            </Td>
            <Td>{dayjs(result.end_time).format('DD/MM/YYYY HH:mm:ss')}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}
