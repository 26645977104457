export class Resource {
  constructor({ endpoint, bearer }, library) {
    this.endpoint = endpoint
    this.bearer = bearer
    this.library = library

    this.url = this.library.baseUrl + endpoint
  }

  async fetch(options) {
    return await this.library.fetch({
      endpoint: this.endpoint,
      ...options,
    })
  }

  async find(queryOptions) {
    const response = await this.fetch({ queryParams: queryOptions, method: 'GET' })

    if (response?.results) {
      return response.results
    }

    return response
  }

  async count(queryOptions) {
    const response = await this.fetch({ path: 'count', queryParams: queryOptions, method: 'GET' })

    return response.count
  }

  async create(body) {
    const options = {
      method: 'POST',
      body: JSON.stringify(body),
    }

    return await this.fetch(options)
  }

  async bulkCreate(docs) {
    const options = {
      path: 'bulk',
      method: 'POST',
      body: JSON.stringify(docs),
    }

    return await this.fetch(options)
  }

  async update(id_count, body) {
    const options = {
      path: id_count,
      method: 'PUT',
      body: JSON.stringify(body),
    }

    return await this.fetch(options)
  }

  async delete(id_count) {
    const options = {
      path: id_count,
      method: 'DELETE',
    }

    return await this.fetch(options)
  }

  async get(id_count, queryOptions = {}) {
    const options = {
      queryParams: queryOptions,
      path: id_count,
      method: 'GET',
    }

    return await this.fetch(options)
  }
}

export class Questionnaire extends Resource {
  async getMain({ language = 'en', questionsPerTheme, shuffle = 'false' } = {}) {
    const options = {
      path: `etest/${language}`,
      method: 'GET',
      queryParams: {
        shuffle,
      },
    }

    if (questionsPerTheme) options.queryParams.questionsPerTheme = questionsPerTheme

    return await this.fetch(options)
  }
}

export class User extends Resource {
  async getMe() {
    const options = {
      path: 'me',
      method: 'GET',
    }

    return await this.fetch(options)
  }

  async updateMe(updatedFields) {
    const options = {
      path: 'me',
      method: 'PUT',
      body: JSON.stringify(updatedFields),
    }

    return await this.fetch(options)
  }
}
