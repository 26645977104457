import { Box, Flex } from '@chakra-ui/react'
import { Link } from '@chakra-ui/react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { ReactComponent as GDPRLogo } from '../assets/img/gdpr-edu-dapro.svg'
import { ReactComponent as EuropeLogo } from '../assets/img/flag-eu.svg'
import { LanguagePicker } from './LanguagePicker'
import { ProgressIndicator } from './ProgressIndicator'

export function Header() {
  const { pathname } = useLocation()
  return (
    <Flex
      transition="all 0.2s ease-out"
      flexWrap="wrap"
      as="nav"
      boxShadow="md"
      align="center"
      justify="space-between"
      padding="1.5rem"
      maxHeight={pathname.startsWith('/questions') ? '40vh' : '20vh'}
    >
      <Link maxWidth="150px" flexBasis={['45%', '45%', 'auto', 'auto']} order={[1, 1, 1, 1]} as={RouterLink} to="/">
        <GDPRLogo width="100%" />
      </Link>
      {pathname === '/' && (
        <LanguagePicker m="auto" flexBasis={['100%', '100%', 'auto', 'auto']} order={[3, 3, 2, 2]} />
      )}
      {pathname.startsWith('/questions') && (
        <>
          <ProgressIndicator flexBasis={['100%', '100%', 'auto', 'auto']} order={[3, 3, 2, 2]} />
        </>
      )}
      <Box maxWidth="180px" flexBasis={['45%', '45%', 'auto', 'auto']} order={[2, 2, 3, 3]}>
        <EuropeLogo width="100%" />
      </Box>
    </Flex>
  )
}
