import { Box, Button } from '@chakra-ui/react'
import { MdPrint } from 'react-icons/md'
import { BlobProvider } from '@react-pdf/renderer'
import { useEffect, useMemo, useState } from 'react'
import { useLanguage } from '../../context/language'

export function ExportPDFButton({ document, filename, ...props }) {
  const [exportRequest, setExportRequest] = useState(false)
  const { t } = useLanguage()

  const ExportButton = ({ url, loading, error }) => {
    useEffect(() => {
      if (url) {
        window.open(url)
      }
      // eslint-disable-next-line
    }, [url])

    const buttonProps = {}
    if (loading) {
      buttonProps.isLoading = true
      buttonProps.loadingText = t('Button.ExportPdf.Generating')
    } else if (error) {
      buttonProps.isDisabled = true
    }

    if (exportRequest) {
      buttonProps.href = url
      buttonProps.target = '_blank'
      buttonProps.as = 'a'
    } else {
      buttonProps.onClick = () => {
        setExportRequest(true)
      }
    }

    return (
      <Button
        rightIcon={
          <Box fill="blue.900">
            <MdPrint size="1.3em" />
          </Box>
        }
        {...buttonProps}
        {...props}
      >
        {t('Button.ExportPdf')}
      </Button>
    )
  }

  const ExportLoaded = () =>
    useMemo(
      () => (
        <BlobProvider document={document} fileName={filename}>
          {({ url, loading, error }) => <ExportButton url={url} loading={loading} error={error} />}
        </BlobProvider>
      ),
      [],
    )

  if (exportRequest) {
    return <ExportLoaded />
  } else {
    return <ExportButton />
  }
}
