import { Container, Heading, Text } from '@chakra-ui/react'

export function OverviewScreen() {
  return (
    <Container>
      <Heading>Overview</Heading>
      <Text>Welcome to the admin dashboard.</Text>
    </Container>
  )
}
